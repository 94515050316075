import React, { useRef, useState } from 'react';
import InputField, { InputFieldHandle } from '~/components/InputField';
import ModalCountryPicker, {
  ModalCountryPickerHandler,
} from '~/components/ModalCountryPicker';
import { COUNTRIES } from '~/data/constants';
import { Country } from '~/data/models/marketProfile';
import { SIGN_UP_TOTAL_STEPS } from '~/screens/Auth/constants';
import AuthLayout from '~/screens/Auth/layout/AuthLayout';
import {
  LanguageProps,
  SubmitButtonProps,
} from '~/screens/Auth/layout/AuthLayout/types';
import validateField from '~/screens/Auth/utils/validateField';
import { t } from '~/utils/i18n';
import { isValidEmail, isValidPhone, validatePhone } from '~/utils/validators';

export type ContactInfo = {
  email: string;
  phoneCountryCode: string;
  phone: string;
};

export type SignUpContactLayoutProps = {
  initialEmail?: string;
  initialPhone?: string;
  inputProgress: number;
  firstName: string;
  country?: Country;
  loading?: boolean;
  emailExists?: boolean | null;
  phoneExists?: boolean | null;
  onBack: () => void;
  onUpdateCountry: (country: Country) => void;
  onNext: (contactInfo: ContactInfo) => void;
} & LanguageProps;

export default function SignUpContact({
  initialEmail = '',
  initialPhone = '',
  inputProgress,
  firstName,
  country,
  loading,
  selectedLocale,
  emailExists,
  phoneExists,
  onBack,
  onUpdateCountry,
  onNext,
  onSelectedLocale,
}: SignUpContactLayoutProps): JSX.Element {
  const countryModalizeRef = useRef<ModalCountryPickerHandler>(null);

  const emailRef = useRef<InputFieldHandle>(null);
  const emailConfirmationRef = useRef<InputFieldHandle>(null);
  const phoneRef = useRef<InputFieldHandle>(null);

  const [email, setEmail] = useState<string>(initialEmail);
  const [emailConfirmation, setEmailConfirmation] = useState<string>('');
  const [phone, setPhone] = useState<string>(initialPhone);

  const [validatingEmail, setValidatingEmail] = useState<string | undefined>();
  const [validatingPhone, setValidatingPhone] = useState<string | undefined>();

  const isValidInput = (): boolean => {
    return isValidEmail(email) && isValidPhone(phone);
  };

  const { state: emailState, info: emailInfo } = validateField({
    field: email,
    isValidField: isValidEmail,
    detectErrors: (field) =>
      emailExists && validatingEmail === field
        ? [t('inputValidation.emailExists')]
        : [],
    info: {
      success: t('inputValidation.validEmail'),
    },
  });

  const { state: emailStateConfirmation, info: emailInfoConfirmation } =
    validateField({
      field: emailConfirmation,
      isValidField: (field) => email === field,
      info: {
        success: t('inputValidation.emailsMatch'),
        error: t('inputValidation.emailsNotMatch'),
      },
    });

  const { state: phoneState, info: phoneInfo } = validateField({
    field: phone,
    isValidField: isValidPhone,
    detectErrors: (field) =>
      phoneExists && validatingPhone === field
        ? [t('inputValidation.phoneExists')]
        : validatePhone(field),
    info: {
      success: t('inputValidation.validWhatsApp'),
    },
  });

  const handleNext = () => {
    if (isValidInput() && onNext && country) {
      setValidatingEmail(email);
      setValidatingPhone(phone);
      onNext({
        email,
        phoneCountryCode: COUNTRIES[country].code,
        phone,
      });
    }
  };

  const headerProps = {
    title: `${t('hi')} ${firstName} ✌🏼`,
    descp: t('signUpContact.descp'),
    progress: inputProgress,
    total: SIGN_UP_TOTAL_STEPS,
  };

  const submitButtonProps: SubmitButtonProps = {
    isValidInput,
    text: t('next'),
    loading,
    onPress: handleNext,
    minWidth: 260,
    size: '2xl',
  };

  const languageProps = {
    selectedLocale,
    onSelectedLocale,
  };

  return (
    <AuthLayout
      onBack={onBack}
      headerProps={headerProps}
      submitButtonProps={submitButtonProps}
      languageProps={languageProps}
    >
      <>
        <InputField
          ref={emailRef}
          testID={'inputEmail'}
          state={emailState}
          infoLabel={emailInfo}
          iconName={'mail-01'}
          label={t('signUpContact.emailLabel')}
          placeholder={t('signUpContact.emailPlaceholder')}
          value={email}
          onChangeText={(value) => setEmail(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          keyboardType={'email-address'}
          onSubmitEditing={() => emailConfirmationRef.current?.focus()}
        />
        <InputField
          ref={emailConfirmationRef}
          testID={'inputEmailConfirmation'}
          state={emailStateConfirmation}
          infoLabel={emailInfoConfirmation}
          iconName={'mail-01'}
          label={t('signUpContact.emailConfirmationLabel')}
          placeholder={t('signUpContact.emailPlaceholder')}
          value={emailConfirmation}
          onChangeText={(value) => setEmailConfirmation(value)}
          blurOnSubmit={false}
          returnKeyType={'next'}
          autoCapitalize={'none'}
          keyboardType={'email-address'}
          onSubmitEditing={() => phoneRef.current?.focus()}
          contextMenuHidden
        />
        <InputField
          ref={phoneRef}
          testID={'inputPhone'}
          state={phoneState}
          infoLabel={phoneInfo}
          country={country}
          onPhonePress={() => countryModalizeRef.current?.open()}
          keyboardType={'numeric'}
          label={t('signUpContact.phoneLabel')}
          placeholder={t('signUpContact.phonePlaceholder')}
          value={phone}
          blurOnSubmit={false}
          returnKeyType={'next'}
          onChangeText={(value) => setPhone(value)}
          onSubmitEditing={() => handleNext()}
        />
        {country ? (
          <ModalCountryPicker
            testID={'countryPicker'}
            ref={countryModalizeRef}
            initialSelectedCountry={country}
            onUpdateCountry={(country) => {
              countryModalizeRef.current?.close();
              onUpdateCountry(country);
            }}
          />
        ) : null}
      </>
    </AuthLayout>
  );
}
