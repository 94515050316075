import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { AdsTargetingFilter } from '~/data/models/admin';
import { CampaignDetails, Regions } from '~/data/models/campaign';
import { INSTITUTES_TARGETING } from '~/data/operations/admin';
import {
  CAMPAIGN,
  CREATE_BANNER_ASSET,
  DELETE_CAMPAIGN,
  EDIT_CAMPAIGN,
  REGIONS,
} from '~/data/operations/campaign';
import {
  readCampaignQuery,
  writeCampaignQuery,
} from '~/data/operations/campaign/helpers';
import {
  CreateBannerAssetInput,
  EditCampaignInput,
} from '~/data/types/graphql';
import { AdminStackParamList, AdsStackParamList } from '~/navigation/types';
import { formattedDateTime } from '~/utils/dates';
import EditAdLayout from './layout';
import { getImageBlob } from './utils';

type EditAdNavProp = StackNavigationProp<AdsStackParamList, 'EditAd'>;
type EditAdRouteProp = RouteProp<AdsStackParamList, 'EditAd'>;

export default function EditAd(): JSX.Element {
  const navigation = useNavigation<EditAdNavProp>();
  const [filters, setFilters] = useState<AdsTargetingFilter[]>([]);

  const {
    params: { id },
  } = useRoute<EditAdRouteProp>();
  const campaignId = decodeURIComponent(id);

  const [editCampaign] = useMutation(EDIT_CAMPAIGN);

  const { data: campaignData, loading: campaignLoading } = useQuery(CAMPAIGN, {
    variables: { id: campaignId },
  });

  const { data: regionsData } = useQuery(REGIONS);

  const { data: targetingData, loading: targetingLoading } = useQuery(
    INSTITUTES_TARGETING,
    {
      variables: {
        targetingFilters: filters,
      },
    },
  );

  const { openDrawer } =
    useNavigation<DrawerNavigationProp<AdminStackParamList>>();

  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);

  const [createBannerAsset] = useMutation(CREATE_BANNER_ASSET, {
    update(cache, { data }) {
      const vars = { id: campaignId };
      const newAsset = data?.createBannerAsset?.asset;
      const campaign = readCampaignQuery({
        cache,
        variables: vars,
      });

      if (!newAsset || !campaign?.campaign) {
        return;
      }

      const { campaign: campaignData } = campaign;

      const oldAssets = campaign?.campaign?.assets ?? [];

      const updatedCampaign: CampaignDetails = {
        ...campaignData,
        assets: [...oldAssets, newAsset],
      };

      writeCampaignQuery({
        cache,
        variables: vars,
        data: {
          __typename: 'Query',
          campaign: updatedCampaign,
        },
      });
    },
  });

  const campaign = campaignData?.campaign as CampaignDetails;

  const addAsset = async (input: Partial<CreateBannerAssetInput>) => {
    try {
      const smallImage = await getImageBlob(input?.assetSmallImage);
      const largeImage = await getImageBlob(input?.assetLargeImage);

      const inputData: CreateBannerAssetInput = {
        campaign: campaignId,
        trackingUrl: input?.trackingUrl ?? '',
        assetSmallImage: smallImage,
        assetLargeImage: largeImage,
        targetingFilters: filters,
      };

      await createBannerAsset({
        variables: {
          input: inputData,
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        Snackbar.show(error.message);
      }
    }
  };

  const onUpdateCampaign = async ({
    id,
    name,
    utm,
    startDate,
    endDate,
    type,
    customer,
    orderId,
  }: EditCampaignInput) => {
    try {
      const { data } = await editCampaign({
        variables: {
          input: {
            id,
            name,
            utm,
            startDate: formattedDateTime(startDate.dateString),
            endDate: formattedDateTime(endDate.dateString),
            type,
            customer,
            orderId,
          },
        },
      });
      if (data) {
        Snackbar.show('Campaign updated!');
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteCampaign = async () => {
    try {
      await deleteCampaign({
        variables: {
          input: {
            id: campaignId,
          },
        },
      });
      navigation.navigate('Ads', { refresh: true });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };
  return (
    <EditAdLayout
      campaign={campaign}
      campaingLoading={campaignLoading}
      onAddAsset={addAsset}
      onDeleteCampaign={onDeleteCampaign}
      onBack={() => navigation.goBack()}
      openDrawer={openDrawer}
      regions={regionsData?.regions as Regions[]}
      updateCampaign={onUpdateCampaign}
      onOpenAsset={({ id, campaignName, campaignId }) =>
        navigation.navigate('EditAsset', { id, campaignName, campaignId })
      }
      targetingData={targetingData}
      targetingLoading={targetingLoading}
      filters={filters}
      setFilters={setFilters}
    />
  );
}
